var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
      },
      [
        _c("img", {
          staticClass: "mx-auto mb-4 max-w-full",
          attrs: {
            src: require("@/assets/images/pages/404.png"),
            alt: "graphic-404",
          },
        }),
        _c(
          "h1",
          { staticClass: "sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color" },
          [_vm._v("\n      401 - Pagina não autorizada!\n    ")]
        ),
        _c(
          "p",
          { staticClass: "sm:mx-0 mx-4 mb-4 sm:mb-16 d-theme-text-inverse" },
          [
            _vm._v(
              "\n      Você não possui permissão para acessar a pagina que procura.\n    "
            ),
          ]
        ),
        _c(
          "vs-button",
          { attrs: { "vs-justify": "flex-end", size: "large", to: "/" } },
          [_vm._v("\n      Voltar para o inicio\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }